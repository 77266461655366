<template>
  <section>
    <b-table-simple
      id="guides-table"
      responsive
      borderless
      fixed
      class="table"
      v-if="bills?.length"
    >
      <thead>
      <th class="text-align-center">
        <Ellipsis>Situação</Ellipsis>
      </th>

      <th v-if="type === 'bills_to_receive'">
        <div class="tooltip-wrapper">
          <Ellipsis>Recebimento</Ellipsis>
          <span>
              <HelpCircle class="icon" id="bill-due-date-tooltip" />
              <b-tooltip
                target="bill-due-date-tooltip"
                triggers="hover"
                placement="bottom"
              >
                Data em que você recebeu o valor na clínica.
              </b-tooltip>
            </span>
        </div>
      </th>
      <th v-if="type === 'bills_to_receive'">
        <div class="tooltip-wrapper">
          <Ellipsis> Pagamento</Ellipsis>
          <span>
              <HelpCircle class="icon" id="bill-payment-date-tooltip" />
              <b-tooltip
                target="bill-payment-date-tooltip"
                triggers="hover"
                placement="bottom"
              >
                Data em que você recebeu o valor na conta.
              </b-tooltip>
            </span>
        </div>
      </th>
      <th v-if="type === 'bills_to_receive'">
        <Ellipsis>Atendimento</Ellipsis>
      </th>

      <th v-if="type === 'bills_to_pay'">
        <Ellipsis>Vencimento</Ellipsis>
      </th>
      <th v-if="type === 'bills_to_pay'">Criado</th>
      <th v-if="type === 'bills_to_pay'">
        <div class="tooltip-wrapper">
          <Ellipsis>Pagamento</Ellipsis>
          <span>
              <HelpCircle class="icon" id="bill-payment-date-tooltip" />
              <b-tooltip
                target="bill-payment-date-tooltip"
                triggers="hover"
                placement="bottom"
              >
                Data em que o valor deixou a conta.
              </b-tooltip>
            </span>
        </div>
      </th>

      <th>
        <Ellipsis
        >Categoria{{
            type === 'bills_to_pay' ? '/Centro de custo' : ''
          }}
        </Ellipsis
        >
      </th>
      <th v-if="type === 'bills_to_receive'">
        <Ellipsis>Responsável</Ellipsis>
      </th>
      <th>
        <Ellipsis>Favorecido</Ellipsis>
      </th>
      <th>
        <Ellipsis>Descrição</Ellipsis>
      </th>
      <th>
        <Ellipsis>Paciente</Ellipsis>
      </th>
      <th>{{ type === 'bills_to_pay' ? 'Pago' : 'Rec.' }} com</th>
      <th v-if="type === 'bills_to_receive'">Val. total</th>
      <th v-if="type === 'bills_to_receive'">Desconto</th>
      <th>Val. final</th>
      <th v-if="type === 'bills_to_receive'">Val. líquido</th>
      <th>
        <Ellipsis>Conferido</Ellipsis>
      </th>
      <th class="no-print actions-header"></th>
      </thead>
      <tbody v-for="(bill, index) in bills" :key="index">
      <tr class="bill-row" :class="{ 'neutral-bg': index % 2 }">
        <td>
            <span
              class="situation-tag"
              :style="`background: ${colors[getBillStatus(bill)]}`"
            >
              {{ getBillStatus(bill) }}
            </span>
        </td>
        <td class="text-align-center">
          {{ getDueDate(bill) }}
        </td>

        <td class="text-align-center" v-if="type === 'bills_to_pay'">
          {{ bill?.created_at && parseDate(bill.created_at) }}
        </td>
        <td class="text-align-center" v-else>
          <Ellipsis>
            {{ getPaymentDate(bill) ?? '-' }}
          </Ellipsis>
        </td>

        <td class="text-align-center" v-if="type === 'bills_to_pay'">
          <Ellipsis>
            {{ getPaymentDate(bill) }}
          </Ellipsis>
        </td>
        <td class="text-align-center" v-if="type === 'bills_to_receive'">
          {{ bill?.service_date ? parseDate(bill.service_date) : '-' }}
        </td>
        <td>
          <p>
            <img
              v-if="bill?.recurrence && bill?.repeat_on === '1 month'"
              src="/icons/month-recurrence.svg"
              height="18"
              alt=""
              v-b-tooltip.bottom
              title="Conta recorrente mensal"
            />
            <img
              v-if="bill?.recurrence && bill?.repeat_on === '1 year'"
              src="/icons/year-recurrence.svg"
              height="18"
              alt=""
              v-b-tooltip.bottom
              title="Conta recorrente anual"
            />
            {{ bill?.category?.name }}
          </p>
          <p :id="`cost_center-${index}`">
            {{ bill?.cost_center[0]?.name }}
          </p>
        </td>
        <b-popover
          :target="`cost_center-${index}`"
          triggers="hover"
          placement="bottom"
        >
          <template #title>Centro de custo</template>
          <p v-for="cc in bill?.cost_center" :key="cc?.id">
            {{ cc?.name }}
          </p>
        </b-popover>
        <td v-if="type === 'bills_to_receive'">
          <Ellipsis>{{ bill?.creator?.name ?? '-' }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ bill?.beneficiary?.name ?? '-' }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ bill?.description ?? '-' }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{ bill?.patient?.name ?? '-' }}</Ellipsis>
        </td>
        <td>
          <Ellipsis>{{
              bill?.payments
                ?.map(payment => payment.payment_method?.name)
                .join(', ')
            }}
          </Ellipsis>
        </td>
        <td v-if="type === 'bills_to_receive'">
          <div v-if='!bill?.bill_items'>
            {{ parseNumberToMoney(bill?.amount || 0) }}
          </div>
          <div v-else>
            {{ getInicialValue(bill) }}
          </div>
        </td>
        <td v-if="type === 'bills_to_receive'">
          <div v-if="bill?.currency && bill?.discount && !bill?.bill_items.length">
            {{
              bill.currency === '%' ?
              parseNumberToMoney(bill?.amount * bill?.discount / 100 || 0) :
              parseNumberToMoney(bill?.discount)
            }}
          </div>
          <div v-else-if="bill?.bill_items.length">
            {{
              getTotalDiscountValue(bill)
            }}
          </div>
          <div v-else>
            {{ '-' }}
          </div>
        </td>
        <td>
          {{ getFinalValue(bill) }}
        </td>
        <td v-if="type === 'bills_to_receive'">
          {{ getTotalAmount(bill) }}
        </td>
        <td>
          <label class="ml-2">
            <Check
              v-model="bill.conferred"
              :value="bill.conferred"
              @change="onChangeConfered(bill)"
            />
          </label>
        </td>
        <td class="no-print">
          <div class="actions">
            <b-button
              v-b-toggle="`collapse-${bill?.id}-${index}`"
              class="toggle-btn p-0"
              @click="rotateIcon(bill?.id)"
              :disabled="!bill?.payments?.length"
            >
              <ChevronDown
                class="arrow"
                :class="rotatedList.includes(bill?.id) ? 'rotated' : ''"
              />
            </b-button>
            <div v-if="bill.category.name === 'Produto'">
              <span :id="`tooltip_${index}`">
                <Clip class="file-icon" @click="openFileModal(bill)"/>
              </span>
              <b-tooltip v-if="hasProductAttachedFile(bill)" :target="`tooltip_${index}`" triggers="hover">
                Arquivos anexados
              </b-tooltip>
              <b-tooltip v-else :target="`tooltip_${index}`" triggers="hover">
                Anexar arquivos
              </b-tooltip>
            </div>

            <div v-else>
              <span :id="`tooltip_${index}`">
                <Clip class="file-icon"  @click="openFileModal(bill)"/>
              </span>
              <b-tooltip v-if="bill?.bill_items?.length" :target="`tooltip_${index}`" triggers="hover">
                Arquivos anexados
              </b-tooltip>
              <b-tooltip v-else :target="`tooltip_${index}`" triggers="hover">
                Anexar arquivos
              </b-tooltip>
            </div>
            <div v-can="'FiConRec4'">
              <Icon tooltip="Editar beneficiário">
                <Edit
                  class="icon"
                  @click="onEdit && onEdit(bill.id, bill?.bill_items?.length)"
                />
              </Icon>
            </div>
            <Icon
              v-show-feature="'nota-fiscal'"
              v-if="
                  type === 'bills_to_receive' && showFeature('Notas Fiscais')
                "
              :tooltip="bill.nfs_id ? 'Visualizar NFS-e' : 'Emitir NFS-e'"
            >
              <IssuedNfeIcon
                v-if="bill.nfs_id"
                class="icon issued-nf"
                @click="downloadNFSePDF(bill)"
              />
              <NfeIcon v-else class="icon" @click="issuNewNFSe(bill)" />
            </Icon>
          </div>
        </td>
      </tr>
      <tr class="collapsable-row">
        <td :colspan="type === 'bills_to_receive' ? '14' : '11'">
          <b-collapse :id="`collapse-${bill?.id}-${index}`" class="collapse">
            <SubTableInstallments
              :type="type"
              :bill="bill"
              :onEdit="onEdit"
              @selected-bills="selectedBills"
              :getBills="getBills"

            />
          </b-collapse>
        </td>
      </tr>
      </tbody>
    </b-table-simple>
    <v-no-filter-results
      v-else-if="!bills?.length"
      message="Os filtros aplicados não geraram nenhum resultado."
    />
    <BillFileModal
      :bill="bill"
      @reloadBills="getBills"
      @editFile="editFile"
    />
    <WarehouseEntryFileModal
      @reload-entries="getBills" 
      :entry="getSelectedEntry"
      :file="getSelectedEntry?.files[0]"
      @close-modal="cleanSelectedEntry"
    />
    <GenericAttachFileModal
      file-type="arquivo"
      :editing-file="getSelectedEntry?.length ? getSelectedEntry?.files[0] : file"
      @saveFile="saveFile"
    />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { showFeature } from '@/utils/permissionsHelp'
import apiNF from '@/modules/nfe/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    bills: { type: Array, required: true },
    onEdit: { type: Function, required: false },
    type: { type: String, required: false },
    selectedInstallments: Array,
    getBills: Function
  },
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Icon: () => import('@/components/General/Icon'),
    Edit: () => import('@/assets/icons/edit.svg'),
    'v-no-filter-results': () => import('@/components/General/noFilterResults'),
    SubTableInstallments: () =>
      import('@/modules/financial/components/SubTableInstallments'),
    IssuedNfeIcon: () => import('@/assets/icons/nf-issued-icon.svg'),
    NfeIcon: () => import('@/assets/icons/Icon_NFe.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Check: () => import('@/components/General/Check'),
    Clip: () => import('@/assets/icons/paperclip.svg'),
    WarehouseEntryFileModal: () => import('@/components/Warehouse/WarehouseEntryFileModal'),
    GenericAttachFileModal: () => import('@/components/General/GenericAttachFileModal.vue'),
    BillFileModal: () => import('@/modules/financial/modals/BillFileModal.vue')
  },
  data() {
    return {
      colors: {
        Pago: 'var(--states-success-soft-green)',
        Vencido: '#FED2CE',
        Pendente: 'var(--light-orange-200)',
        Parcelado: 'var(--blue-100)'
      },
      parseStatus: {
        paid_out: 'Pago',
        expired: 'Vencido',
        pending: 'Pendente'
      },
      rotatedList: [],
      clinic: getCurrentClinic(),
      bill: null,
      file: {}
    }
  },
  computed: {
    ...mapGetters('warehouse', ['getSelectedEntry'])
  },
  methods: {
    ...mapActions('warehouse', ['updateSelectedEntry']),
    showFeature,
    parseNumberToMoney,
    rotateIcon(id) {
      this.rotatedList.includes(id)
        ? this.rotatedList.pop(id)
        : this.rotatedList.push(id)
    },
    editFile(file){
      this.file = file
    },
    parseDate(datetime) {
      if (datetime && datetime !== '0000-00-00') {
        return this.moment(datetime).format('DD/MM/YYYY');
      } else {
        return '-';
      }
    },
    hasProductAttachedFile(bill) {
      if (!bill?.bill_products?.length) {
        return false
      }
      return bill.bill_products[0].warehouse_entry?.files?.length > 0
    },
    cleanSelectedEntry() {
      this.updateSelectedEntry(null)
    },
    openFileModal(bill) {
      if (bill.bill_products[0]?.warehouse_entry) {
        this.bill = null
        this.updateSelectedEntry(bill.bill_products[0].warehouse_entry)
        this.$bvModal.show('warehouse-entry-file-modal')
      } else {
        this.bill = bill
        this.$bvModal.show('bill-file-modal')      
      }
    },
    saveFile(file) {
      const isLoading = this.$loading.show()
      if(!this.bill){
        if (file.id) {
          this.api.updateWarehouseEntryFile(
            file.id,
            { filename: file.filename }
          ).then(() => {
            this.$toast.success('Arquivo atualizado com sucesso!')
            this.getBills()
          }).catch(
            () => {
              this.$toast.error('Ocorreu um erro ao atualizar o arquivo!')
            }
          ).finally(() => {
            isLoading.hide()
          })
        } else {
          const dataForm = new FormData()
          dataForm.append(`file`, file.file)
          dataForm.append(`filename`, file.filename)
          dataForm.append('clinic_id', this.getSelectedEntry.clinic_id)
          dataForm.append('warehouse_entry_id', this.getSelectedEntry.id)
          this.api.createWarehouseEntryFile(
            dataForm
          ).then(() => {
            this.$toast.success('Arquivo criado com sucesso!')
            this.getBills()
          }).catch(
            () => {
              this.$toast.error('Ocorreu um erro ao criar o arquivo!')
            }
          ).finally(() => {
            isLoading.hide()
          })
        }
      }
      else{
        if (file.id) {
          const formData = new FormData()
          formData.append(`file`, null)
          formData.append(`filename`, file.filename)
          formData.append('clinic_id', this.clinic.id)
          formData.append('bill_id', this.bill.id)

          this.api
            .updateBillFile(file.id, formData, {
              onUploadProgress: event => {
                this.progress = Math.round((event.loaded * 100) / event.total)
              }
            })
            .then(response => {
              this.$toast.success('Arquivo enviado com sucesso')
              this.getBills()
            })
            .catch(error => {
              this.$toast.error(error.message)
              this.error = true
            })
            .finally(() => {
              isLoading.hide()
            })          
        } else {
          const dataForm = new FormData()
          dataForm.append(`file`, file.file)
          dataForm.append(`filename`, file.filename)
          dataForm.append('clinic_id', this.clinic.id)
          dataForm.append('bill_id', this.bill.id)

          this.api
            .createBillFile(dataForm, {
              onUploadProgress: event => {
                this.progress = Math.round((event.loaded * 100) / event.total)
              }
            })
            .then(response => {
              this.$toast.success('Arquivo enviado com sucesso')
              this.error = false
              this.bill.bill_files.push(response.data)
              this.getBills()
            })
            .catch(error => {
              this.$toast.error(error.message)
              this.error = true
            })
            .finally(() => {
              isLoading.hide()
            })
        }
      }
    },
    getDueDate(bill) {
      if (!bill?.payments) return '-'
      return bill?.payments
        ?.map(payment => this.parseDate(payment?.due_date))
        .filter(this.onlyUnique)
        .join(', ')
    },
    async onChangeConfered(bill) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateBillConferred(bill.id, {
          conferred: bill.conferred
        })
        if (bill.conferred) {
          this.$toast.success('Conta conferida com sucesso!')
        } else {
          this.$toast.success('Conta desmarcada com sucesso!')
        }
        await this.getBills()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getPaymentDate(bill) {
      if (!bill?.payments) return '-'
      return bill?.payments
        ?.map(payment =>
          payment.installments
            .filter(
              installment =>
                installment.status === 'paid_out' &&
                this.moment(installment.payment_date).isValid()
            )
            .map(installment => this.parseDate(installment?.payment_date))
            .filter(this.onlyUnique)
        )
        .join(', ')
    },
    getBillStatus(bill) {
      if (
        !bill?.payments?.find(payment =>
          payment?.installments?.find(
            installment => installment?.status !== 'paid_out'
          )
        )
      ) {
        return 'Pago'
      } else if (
        bill?.payments?.find(payment =>
          payment?.installments?.find(
            installment => installment?.status === 'expired'
          )
        )
      ) {
        return 'Vencido'
      } else if (
        bill?.payments?.find(payment => payment?.installments?.length > 1)
      ) {
        return 'Parcelado'
      } else {
        return 'Pendente'
      }
    },
    selectedBills(billsToPay, billsToReceive) {
      this.$emit('selected-bills', billsToPay, billsToReceive)
    },
    getInicialValue(bill) {
      if (Array.isArray(bill?.bill_items) && bill?.bill_items.length !== 0) {
        const sum = bill?.bill_items?.reduce((acc, payment) => {
          return acc + payment?.value
        }, 0)
        return this.parseNumberToMoney(sum)
      }
      return this.parseNumberToMoney(bill.amount)
    },
    getTotalDiscountValue(bill) {
      let sum = bill?.bill_items?.reduce((acc, item) => {
        if (item?.format === '%') {
          return acc + (item?.value * item?.discount / 100)
        } else {
          return acc + item?.discount
        }
      }, 0)

      if (bill?.currency === 'R$' && bill?.discount > 0) {
        sum = +bill.discount
      }
      return this.parseNumberToMoney(sum)
    },
    getFinalValue(bill) {
      if (bill.final_amount !== null) {
        return this.parseNumberToMoney(bill.final_amount)
      }
      const sum = bill?.payments?.reduce((acc, payment) => {
        return acc + payment?.amount
      }, 0)
      return this.parseNumberToMoney(sum)
    },
    getTotalAmount(bill) {
      const sum = bill?.payments?.reduce((acc, payment) => {
        return (
          acc +
          payment?.installments?.reduce((acc, installment) => {
            return acc + installment?.amount
          }, 0)
        )
      }, 0)

      return this.parseNumberToMoney(sum)
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index
    },
    async issuNewNFSe(bill) {
      const params = {
        bill_id: bill.id,
        // data_emissao: new Date(bill.service_date),
        servico: {
          aliquota: '',
          valor_servicos: bill?.amount
        },
        tomador: {
          nome: bill?.patient?.name,
          cnpj_cpf: bill?.patient?.cpf,
          patient_id: bill?.patient?.id,
          email: bill?.patient?.email
        }
      }
      this.$store.dispatch('nfe/updateIsPatientDisabled', true)
      this.$store.dispatch('nfe/updateNfModalData', params)
      this.$bvModal.show('new-invoice-modal')
    },
    async downloadNFSePDF(bill) {
      try {
        const { data } = await apiNF.getNFbyId(bill.nfs_id)
        if (data.caminho_pdf_nota_fiscal) {
          window.open(data.caminho_pdf_nota_fiscal, '_blank')
        } else {
          this.$toast.warning('Nota em processo de autorização, aguarde!')
        }
      } catch (err) {
        this.$toast.error(err.message)
      }
    }
  },
  watch: {
    bills: function() {
      this.selected = []
      this.$emit('selected-bills', [])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

.subtable {
  padding-top: 10px;
}

.actions-header {
  width: 100px;
}

tr {
  border: none !important;
}

tr:nth-child(even) {
  background: var(--neutral-000) !important;
}

td {
  vertical-align: middle !important;

  .situation-tag {
    width: 100%;
    border-radius: 16px;
    padding: 4px 10px;
    background-color: var(--neutral-300);
    display: inline-block;
    text-align: center;
  }

  .blue-text {
    color: var(--blue-500);
  }

  .checkbox-td {
    display: flex;
    justify-content: center;

    .checkbox {
      margin-bottom: 20px !important;
      vertical-align: middle !important;
      display: flex;
    }
  }

  .file-icon {
    width: 24px;
    stroke: var(--dark-blue) !important;
    cursor: pointer;

    &.no-file {
      stroke: var(--type-placeholder) !important;
      cursor: not-allowed;
    }
  }

  .options {
    cursor: pointer;
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      width: 180px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
      0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-weight: 600;
        width: 100%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          text-align: left;
          padding: 12px 18px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }
}

.sub-table {
  border: 1px solid var(--neutral-200);
  padding: 1rem;
  border-radius: 15px;
  margin: 1rem 0;
}

.bill-row {
  td {
    padding: 0.75rem !important;
  }
}

.neutral-bg {
  background: var(--neutral-100) !important;
}

.collapsable-row {
  td {
    padding: 0 !important;
    border: none !important;
  }
}

.toggle-btn {
  background: var(--blue-100);
  border: none;
  padding: 5px 8px !important;

  .icon {
    width: 20px;
    transition: all 500ms;
    stroke: var(--blue-500);
  }
}

.issued-nf {
  cursor: not-allowed;
}

@media (max-width: 1440px) {
  .table-eyecare th,
  td {
    font-size: 11px !important;
  }
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

@media print {
  table {
    transform: scale(0.95);
  }
  tbody {
    tr {
      border-bottom: 1px dashed var(--neutral-300);
    }
  }
  td,
  th,
  p {
    max-width: 20ch !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  td,
  p {
    font-size: 10px !important;
  }
  th {
    font-size: 9px !important;
  }
}

.tooltip-wrapper {
  display: flex;
  gap: 3px;
  align-items: center;
}

.arrow {
  width: 14px;
  height: 14px;
}

.arrow {
  transition: rotate 1s;
}

.rotated {
  rotate: -180deg;
  transition: rotate 1s;
}

.actions {
  justify-content: right;
  display: flex;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
